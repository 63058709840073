<template>
  <div class="GlobalTable">
    <GlobalTable
      ref="GlobalTable"
      v-loading="loading"
      :columns="tableColumns"
      :data="seachDataList"
      :currentPage="page.pageNum"
      :total="page.total"
      :isPagination="false"
      @handleCurrentChange="handleCurrentChange"
    >
      <el-table-column label="添加人" slot="createAdminAccount" align="center">
        <template slot-scope="{ row }">
          {{row.createAdminName}}-{{row.createAdminAccount}}
        </template>
      </el-table-column>
      <el-table-column label="操作" slot="operation" align="center">
        <template slot-scope="{ row }">
          <el-button size="mini" type="primary" round @click="$refs['roleDia'].open(row)">编辑</el-button>
          <el-button size="mini" type="danger" round @click="handleDel(row)">删除</el-button>
        </template>
      </el-table-column>
    </GlobalTable>
    <RoleDia ref="roleDia" />
  </div>
</template>

<script>
import _api from "@/utils/request";
import { confirm } from '@/components/common/messagebox'
import RoleDia from './dialog/RoleDia.vue';
export default {
  components: { RoleDia },
  name: "Table",
  data() {
    return {
      seachDataList: [],
      page:{
        pageNum:1,
        pageSize:10,
        total: 0,
      },
      pageNum:1,
      tableColumns: [
        { label: "角色名称", prop: "roleName" },
        { label: "账号数量", prop: "userNum" },
        { label: "角色说明", prop: "roleInfo" },
        // { label: "添加人", prop: "createAdminAccount" },
        { slotName: "createAdminAccount" },
        { label: "添加时间", prop: "createTime" },
        { slotName: 'operation' },
      ],
    };
  },
  created() {
    this.handleCurrentChange();
  },
  computed: {
    SeachParams() {
      return this.$store.state.tagsView.SeachParams
    },
  },
  watch:{
    SeachParams(newVal){
      console.log(newVal)
      this.page.pageNum = 1;
      this.handleCurrentChange()
    },
  },
  methods: {
    // 表格切换页面
    handleCurrentChange(val) {
      if (val) {
        this.pageNum = val
        this.page.pageNum = val;
      }
      const baseRequest = {
        roleId: this.SeachParams.roleId || '',
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
      };
      this.loading = true
      _api.marketerRoleList(baseRequest).then(res => {
        if (res.code === 1) {
          this.seachDataList = res.data
          // this.seachDataList = res.data.records;
          // this.page.total = res.data.total;
          // this.page.pageNum = res.data.current;
          this.loading = false
        }
      }).finally(() => {
        if (typeof this.SeachParams.disuseLoding === 'function') {
          this.SeachParams.disuseLoding();
        }
      });
    },
    handleDel(row) {
      confirm({
        title: '删除角色',
        message: '确认要执行该操作吗？',
        confirm: () => {
          _api.marketerRoleDelete({
            id: row.id
          }).then(() => {
            this.$message.success('操作成功！')
            this.handleCurrentChange()
          })
        }
      })
    },
  },
};
</script>