<template>
  <div>
    <el-dialog title="添加账号" :visible.sync="visible" :close-on-click-modal="false" width="500px">
      <el-form ref="formRef" :model="formData" :rules="formRule" label-width="110px">
        <el-form-item label="运营人员姓名" prop="userName">
          <el-input v-model.trim="formData.userName" maxlength="10" placeholder="请输入员工姓名（10位以内字母或汉字）" type="text" />
        </el-form-item>
        <el-form-item label="所属角色" prop="roleId">
          <el-select v-model="formData.roleId" filterable placeholder="请选择，若无角色需先创建角色" style="width:100%">
            <el-option v-for="item in roleList" :key="item.id" :label="item.name" :value="item.id" />
          </el-select>
        </el-form-item>
        <el-form-item label="手机号" prop="userPhone">
          <el-input v-model.trim="formData.userPhone" maxlength="11" placeholder="请输入手机号" type="text" />
        </el-form-item>
        <el-form-item label="登录账号" prop="userAccount">
          <el-input v-model.trim="formData.userAccount" maxlength="14" placeholder="请输入登录账号（6-14位）" type="text" />
        </el-form-item>
        <el-form-item label="登录密码" prop="userPassword">
          <el-input v-model.trim="formData.userPassword" maxlength="14" placeholder="请输入登录密码（6-14位）" type="text" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="visible = false">取消</el-button>
        <el-button type="primary" @click.native="confirm">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _api from "@/utils/request";
import { formValidateMobile } from '@/utils/form-validate'

const originFormData = {
  userName: '',
  roleId: '',
  userPhone: '',
  userAccount: '',
  userPassword: '',
}
export default {
  name: "AccountDia",
  data() {
    const limitValidator = (rule, value, callback) => {
      const chinaReg = /[\u4e00-\u9fa5]/
      if (chinaReg.test(value)) callback(new Error('请输入字母、数字或特殊字符'))
      if (value.length >= 6 && value.length <= 14) callback()
      callback(new Error('请输入6-14位'))
    };
    const userNameValidate = (rule, value, callback) => {
      const reg = /^[a-zA-Z\u4e00-\u9fa5]*$/
      if (reg.test(value)) callback()
      callback(new Error('请输入字母或汉字'))
    };
    return {
      visible: false,
      formData: originFormData,
      formRule: {
        userName: [
          { required: true, message: '请输入姓名', trigger: 'change' },
          { validator: userNameValidate, trigger: 'change' }
        ],
        roleId: [{ required: true, message: '请选择角色', trigger: 'change' }],
        userPhone: [
          { required: true, message: '请输入手机号', trigger: 'change' },
          { validator: formValidateMobile, trigger: 'change' },
        ],
        userAccount: [
          { required: true, message: '请输入账号', trigger: 'change' },
          { validator: limitValidator, trigger: 'change' },
        ],
        userPassword: [
          { required: true, message: '请输入密码', trigger: 'change' },
          { validator: limitValidator, trigger: 'change' },
        ],
      },
      roleList: []
    };
  },
  methods: {
    open() {
      this.formData = JSON.parse(JSON.stringify(originFormData))
      this.getRoleList()
      this.$refs['formRef']?.clearValidate()
      this.$nextTick(() => {
        this.visible = true
      })
    },
    getRoleList() {
      _api.marketerRoleSelectList().then(res => {
        this.roleList = res.data
      })
    },
    close() {
      this.visible = false
    },
    confirm() {
      this.$refs['formRef'].validate((vaild) => {
        if (vaild) {
          _api.marketerUserSave(this.formData).then(() => {
            this.$emit('confirm', this.type)
            this.$message.success('操作成功！')
            this.close()
          })
        }
      })
    }
  }
};
</script>