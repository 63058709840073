<template>
  <el-dialog
    v-loading="loading"
    title="动态口令登录码"
    :visible.sync="visible"
    :close-on-click-modal="false"
    width="380px"
  >
    <div style="font-size:12px;color:rgb(255, 104, 123);margin-bottom:20px">说明：根据职员手机号生成，职员登录需要使用自己的动态口令</div>
    <div style="text-align:center">
      <VueQr :text="info.qrcode || ''" :size="150"></VueQr>
    </div>
    <span slot="footer"  class="dialog-footer stydata">
      <div class="left_fg">
        <p>账号：{{info.key}}</p>
        <p>密钥：{{info.secret}}</p>
      </div>
      <el-button type="primary" @click.native="visible=false">关闭</el-button>
    </span>
  </el-dialog>
</template>
<script>
import VueQr from "vue-qr"
import _api from "@/utils/request";
export default {
  name: 'CodeDia',
  components: { VueQr },
  data() {
    return {
      visible: false,
      loading: false,
      row: {},
      info: {}
    }
  },
  methods: {
    open(row) {
      this.row = row
      this.genCode()
      this.visible = true
    },
    genCode() {
      this.loading = true
      _api.marketerUserGoogleCode({
        marketerId: this.row.id
      }).then(res => {
        this.info = res.data
      }).finally(() => {
        this.loading = false
      })
    },
    close() {
      this.visible = false
    },
    confirm() {

    }
  }
}
</script>

<style lang="scss" scoped>
.left_fg{
  font-size: 14px;
  text-align: left;
  >p{
    margin-bottom: 10px;
  }
}
.stydata{
  display: inline-block;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>