<template>
  <div class="GlobalFormDemo">
    <el-form :model="formData" inline label-width="80px" size="small" class="el-form-group_isRound">
      <el-form-item label="角色名称" prop="roleId">
        <el-select v-model="formData.roleId" filterable clearable placeholder="请输入角色名称查询">
          <el-option v-for="item in roleList" :key="item.id" :label="item.name" :value="item.id" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button size="small" type="primary" :loading="searchLoading" round @click="handleConfirm">查询</el-button>
      </el-form-item>
      <el-form-item style="float: right">
        <el-button size="small" type="primary" icon="el-icon-plus" @click="$refs['roleDia'].open()">添加角色</el-button>
      </el-form-item>
    </el-form>
    <RoleDia ref="roleDia" @confirm="handleConfirm()" />
  </div>
</template>

<script>
import moment from "moment";
import _api from "@/utils/request";
import RoleDia from './dialog/RoleDia.vue';
export default {
  components: { RoleDia },
  name: 'Form',
  data() {
    return {
      formData: {
        roleId: '',
      },
      roleList: [],
      searchLoading: false
    }
  },
  created(){
    this.getRoleList()
    this.handleConfirm()
  },
  methods: {
    getRoleList() {
      _api.marketerRoleSelectList().then(res => {
        this.roleList = res.data
      })
    },
    //查询
    handleConfirm() {
      let _this = this
      this.searchLoading = true
      const SeachParams = {
        ...this.formData,
        disuseLoding: () => {
          if (typeof _this.stopSearchLoading === 'function') {
            _this.stopSearchLoading()
          }
        },
      } 
      this.$store.commit('tagsView/SEACH_PARAMS',SeachParams)
    },
    stopSearchLoading() {
      this.searchLoading = false
    }
  }
}
</script>
