<template>
  <div class="class-list">
    <GlobalInfoBar
      title="运营后台权限管理"
      content="设置内部人员的管理权限"
    >
      <div slot="right" style="text-align: right;flex:1;font-size:14px;">
        <span>运营后台地址：<span @click="copy()" style="text-decoration: underline;cursor: pointer;">http://shichang.btstars.cn/</span>（点击复制）</span>
      </div>
    </GlobalInfoBar>
    <!-- <Info/> -->
    <el-radio-group v-model="selectType" size="large" style="margin-bottom: 20px">
      <el-radio-button label="01">角色管理</el-radio-button>
      <el-radio-button label="02">账号管理</el-radio-button>
    </el-radio-group>
    <GlobalChunk icon="search" title="筛选查询">
      <template v-if="selectType == '01'">
        <From slot="filter" />
        <Table />
      </template>
      <template v-else-if="selectType == '02'">
        <AForm slot="filter" />
        <ATable />
      </template>
    </GlobalChunk>
  </div>
</template>

<script>
import AForm from './AForm.vue';
import ATable from './ATable.vue';
import From from "./Form";
import Table from "./Table";
export default {
  name: "class-list",
  components: {
    From,
    Table,
    ATable,
    AForm
  },
  data() {
    return {
      selectType: '01'
    };
  },
  methods: {
    copy() {
      const oInput = document.createElement("input");
      oInput.value = 'http://shichang.btstars.cn';
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      oInput.className = "oInput";
      oInput.style.display = "none";
      document.body.removeChild(oInput);
      this.$message.success("复制成功");
    },
  },
};
</script>

<style>
</style>